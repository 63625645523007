export { PageShell };

import React, { useEffect } from "react";
import { PageContextProvider } from "./usePageContext";
import type { PageContext } from "vike/types";
import AppLayout from "../layouts/app";
import { SessionProvider } from "next-auth/react";
import i18n from "../i18n";
import { useMatomo } from "../hooks/useMatomo";

// import "@repos/digikoo-design-system-react/styles";
import "./css/index.css";

// eslint-disable-next-line react-refresh/only-export-components
export const lsLanguageKey = "language";

function PageShell({
  children,
  pageContext,
}: {
  children: React.ReactNode;
  pageContext: PageContext;
}) {
  const Layout = pageContext.config.Layout || AppLayout;
  useMatomo();
  console.log({
    envs: {
      PUBLIC_ENV__BACKEND_API_URL: import.meta.env.PUBLIC_ENV__BACKEND_API_URL,
      PUBLIC_ENV__CONFIGURATOR_API_URL: import.meta.env.PUBLIC_ENV__CONFIGURATOR_API_URL,
      PUBLIC_ENV__MATOMO_URL: import.meta.env.PUBLIC_ENV__MATOMO_URL
    }
  })
  useEffect(() => {
    const userLang =
      pageContext.session?.user?.language?.toLowerCase() as string;
    const lang = localStorage.getItem(lsLanguageKey);

    if (lang && userLang) {
      if (lang === userLang) {
        localStorage.setItem(lsLanguageKey, lang);
        i18n.changeLanguage(lang as string);
      } else {
        i18n.changeLanguage(userLang as string);
        localStorage.setItem(lsLanguageKey, userLang);
      }
    } else {
      i18n.changeLanguage(userLang as string);
      localStorage.setItem(lsLanguageKey, userLang);
    }
  }, []);

  return (
    <React.StrictMode>
      <PageContextProvider pageContext={pageContext}>
        <SessionProvider session={pageContext.session}>
          <Layout>{children}</Layout>
        </SessionProvider>
      </PageContextProvider>
    </React.StrictMode>
  );
}
