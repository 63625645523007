import React from "react";

export const useMatomo = () => {
  const url = import.meta.env.PUBLIC_ENV__MATOMO_URL;
  const isProd = import.meta.env.PROD;
  React.useEffect(() => {
    if (isProd && url) {
      window._paq = window._paq || [];
      window._paq.push(["disableCookies"]);
      window._paq.push(["trackPageView"]);
      window._paq.push(["enableLinkTracking"]);
      window._paq.push(["setTrackerUrl", url + "matomo.php"]);
      window._paq.push(["setSiteId", "3"]);

      const d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.src = url + "matomo.js";
      s?.parentNode?.insertBefore(g, s);
    }
  }, []);
};
