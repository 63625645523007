import { Session } from "next-auth";
import { useEffect, useState } from "react";


export function useRefreshTokenCheck(session: Session | null, update: (data?: any) => Promise<Session | null>) {

    const [loginRedirected, setLoginRedirected] = useState(false);
    //handle session check
    useEffect(() => {
        async function checkSession() {
            if (
                session?.accessTokenExpires &&
                Date.now() < session.accessTokenExpires
            ) {
                return;
            }

            const updatedSession = await update();
            if (
                updatedSession === undefined ||
                updatedSession === null ||
                updatedSession.error === "RefreshAccessTokenError"
            ) {
                if (!loginRedirected) {
                    window.location.href = `/`;
                    setLoginRedirected(true);
                }
            }
        }
        checkSession();
    }, [session, update, loginRedirected]);
}