import i18n from "i18next";
import appDe from "./locales/de/app.json";
import commonDe from "./locales/de/common.json";
import startDe from "./locales/de/start.json";
import scenarioDe from "./locales/de/scenario.json";
import conceptDe from "./locales/de/concept.json";
import appEn from "./locales/en/app.json";
import commonEn from "./locales/en/common.json";
import startEn from "./locales/en/start.json";
import scenarioEn from "./locales/en/scenario.json";
import conceptEn from "./locales/en/concept.json";
import { initReactI18next } from "react-i18next";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import zodDe from "zod-i18n-map/locales/de/zod.json";
import zodEn from "zod-i18n-map/locales/en/zod.json";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  resources: {
    de: {
      zod: zodDe,
      common: commonDe,
      start: startDe,
      app: appDe,
      scenario: scenarioDe,
      concept: conceptDe
    },
    en: {
      zod: zodEn,
      common: commonEn,
      start: startEn,
      app: appEn,
      scenario: scenarioEn,
      concept: conceptEn
    },
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["sup", "sub"]
  },
  defaultNS: "common",
  fallbackLng: "de",
  returnNull: false,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

z.setErrorMap(zodI18nMap);

export default i18n;
