import classNames from "classnames";
import { ContainerGrid } from "@repos/digikoo-design-system-react/grid";
import { HTMLAttributes, ReactElement } from "react";

export type FooterProps = HTMLAttributes<HTMLElement> & {
  theme: "dark" | "light";
  copy?: string;
  items?: {
    title: string;
    href: string;
    target?: "_blank" | "_self" | "_parent" | "_top";
  }[];
};

const Footer = ({
  theme = "dark",
  items,
  copy,
  className,
  ...props
}: FooterProps): ReactElement => {
  return (
    <footer
      className={classNames(className, "dds-relative dds-border-t dds-py-6", {
        "dds-text-black dds-border-gray-200": theme === "dark",
        "dds-text-white dds-border-white": theme === "light",
      })}
      {...props}
    >
      <ContainerGrid>
        <div className="dds-col-start-[content] dds-col-end-[content]">
          <div className="dds-flex dds-gap-x-6 dds-justify-center dds-text-13 dds-leading-tight dds-tracking-wider dds-font-bold">
            {/* Nav */}
            {!!items?.length && (
              <ul className="dds-flex dds-flex-wrap dds-gap-x-6 dds-gap-y-3">
                {/* Copyright */}
                {!!copy && (
                  <li>
                    <a
                      className={classNames("dds-whitespace-nowrap", {
                        "hover:dds-text-primary-600": theme === "dark",
                        "hover:dds-text-white/90": theme === "light",
                      })}
                      href="https://digikoo.de/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {copy}
                    </a>
                  </li>
                )}
                {items.map(({ href, title, target }, idx) => (
                  <li key={idx}>
                    <a
                      className={classNames(
                        "dds-transition-colors dds-duration-200 dds-text-inherit",
                        {
                          "hover:dds-text-primary-600": theme === "dark",
                          "hover:dds-text-white/90": theme === "light",
                        }
                      )}
                      href={href}
                      target={target ?? "_self"}
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </ContainerGrid>
    </footer>
  );
};

export default Footer;
