import { HeaderToolbarProps } from "./headerToolbar";
import LaunchpadIcon from "../../assets/icons/launchpad-icon.svg?react";
import DigiPadIcon from "../../assets/icons/digipad-icon.svg?react";
import ConfiguratorIcon from "../../assets/icons/configurator-icon.svg?react";
import AdminHubIcon from "../../assets/icons/admin-hub-icon.svg?react";

export const resolveProductIcon = (productId: HeaderToolbarProps["productId"]) =>
    ({
      digipad: <DigiPadIcon />,
      configurator: <ConfiguratorIcon />,
      launchpad: <LaunchpadIcon />,
      "admin hub": <AdminHubIcon />,
    }[productId]);
  