
import * as Sentry from "@sentry/react";

export function initSentry() {
    const sentryDsn = import.meta.env.PUBLIC_ENV__SENTRY_DOMAIN;
    const sentryEnv = import.meta.env.PUBLIC_ENV__SENTRY_ENVIRONMENT;
    if (sentryEnv) {
        Sentry.init({
            enabled: (!!sentryEnv && sentryEnv !== "dev"),
            dsn: sentryDsn,
            integrations: [
                Sentry.browserTracingIntegration(),
                // Sentry.replayIntegration({
                //     maskAllText: false,
                //     blockAllMedia: false,
                // }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            environment: sentryEnv
        });
    }

}
