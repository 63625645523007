import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "next-auth/react";
import { Header } from "../components/header/Header";
import Footer from "../components/sections/footer";
import { ContainerGrid } from "@repos/digikoo-design-system-react/grid";
import { useRefreshTokenCheck } from "../hooks/useRefreshTokenCheck";

interface AppLayoutProps {
  children: React.ReactNode;
  activeProductId?: "configurator" | "launchpad" | "digipad";
}

const AppLayout = ({
  children,
  activeProductId,
}: AppLayoutProps): ReactElement => {
  const { t } = useTranslation();

  const { data: session, update } = useSession();
  useRefreshTokenCheck(session, update);

  if (!activeProductId) activeProductId = "configurator";

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex flex-col flex-1">
        <Header />

        {/* Content */}
        <ContainerGrid className="w-full grow auto-rows-max">
          {children}
        </ContainerGrid>

        {/* Footer */}
        <Footer
          className="w-full"
          theme="dark"
          copy={`©${t("footer.copy")}`}
          items={t("footer.items", { returnObjects: true }) as any}
        />
      </div>
    </div>
  );
};

export default AppLayout;
