import { ApplicationDto } from "../../types/application";
import { ResetPasswordDto } from "../../types/resetPassword";
import { User, UserSettings } from "../../types/user.js";
import { sendRequest } from "../baseApiService.js";
import { ApiRequest } from "../interfaces/apiRequest.js";
import { ApiResponseTyped } from "../interfaces/apiResponse.js";

class UserService {
  private readonly token: string;
  private readonly baseUrl: string;

  constructor(token?: string, baseUrl?: string) {
    this.token = token ?? "";
    this.baseUrl = baseUrl ?? (import.meta.env.PUBLIC_ENV__BACKEND_API_URL ?? process.env.PUBLIC_ENV__BACKEND_API_URL);
  }

  async getAuthToken(userName: string, password: string) {
    const request: ApiRequest = {
      endpoint: "user/auth",
      method: "POST",
      body: {
        username: userName,
        password: password,
      },
      authToken: "",
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<UserSettings>>(request);
  }

  async getUser(userId: string) {
    const request: ApiRequest = {
      endpoint: `user/${userId}`,
      method: "GET",
      authToken: this.token,
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<User>>(request);
  }

  async updateUser(userId: string, body: User) {
    const request: ApiRequest = {
      endpoint: `user/${userId}`,
      method: "PUT",
      authToken: this.token,
      body,
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<User>>(request);
  }

  async getUserSettings(userId: string) {
    const request: ApiRequest = {
      endpoint: `user/${userId}/settings`,
      method: "GET",
      authToken: this.token,
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<UserSettings>>(request);
  }
  async forgotPassword(email: string) {
    const request: ApiRequest = {
      endpoint: `user/forgot-password`,
      method: "POST",
      authToken: this.token,
      body: email,
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<string>>(request);
  }
  async checkforgotPasswordToken(token: string, userId: string) {
    const request: ApiRequest = {
      endpoint: `user/check-password-token`,
      method: "POST",
      authToken: this.token,
      body: { token: token, userId: userId },
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<string>>(request);
  }
  async resetPassword(data: ResetPasswordDto) {
    const request: ApiRequest = {
      endpoint: `user/reset-password`,
      method: "POST",
      authToken: this.token,
      body: data,
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<string>>(request);
  }
  async checkVerifyEmailToken(token: string, userId: string) {
    const request: ApiRequest = {
      endpoint: `user/verify-email-token`,
      method: "POST",
      authToken: this.token,
      body: { token: token, userId: userId },
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<string>>(request);
  }

  async maintenanceStatus() {
    const request: ApiRequest = {
      endpoint: `migration/maintenance-status`,
      method: "GET",
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<string>>(request);
  }

  async getApplications(userId: string) {
    const request: ApiRequest = {
      endpoint: `user/${userId}/assigned-applications`,
      method: "GET",
      authToken: this.token,
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<ApplicationDto[]>>(request);
  }

  async resetOtp(id: string) {
    const request: ApiRequest = {
      endpoint: `user/${id}/reset-otp`,
      method: "DELETE",
      authToken: this.token,
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<boolean>>(request);
  }

  async getApplicationByName(name: string) {
    const request: ApiRequest = {
      endpoint: `application/application/${name}`,
      method: "GET",
      baseUrl: this.baseUrl,
    };

    return sendRequest<ApiResponseTyped<ApplicationDto>>(request);
  }
}

export default UserService;
