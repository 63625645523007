import { ApiRequest } from "./interfaces/apiRequest.js";
import { ApiResponse } from "./interfaces/apiResponse.js";
import { v4 as uuidv4 } from 'uuid';

//const BASE_URL = import.meta.env.PUBLIC_ENV__CONFIGURATOR_API_URL;

//console.log("base", BASE_URL);

/**
 * Sends a request to the server.
 * @param options ApiRequest object
 * @returns ApiResponse object
 */
export async function sendRequest<T>(options: ApiRequest): Promise<T> {
  const {
    endpoint,
    method = "GET",
    authToken,
    body,
    responseType = "JSON",
    baseUrl,
  } = options;

  const BASE_URL = baseUrl ?? (import.meta.env.PUBLIC_ENV__CONFIGURATOR_API_URL ?? process.env.PUBLIC_ENV__CONFIGURATOR_API_URL);

  const apiRequest: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      'X-Correlation-ID': uuidv4(),
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
    },
    ...(body && { body: JSON.stringify(body) }),
  };

  const endpointPath = `${BASE_URL}${endpoint}`.replace("undefined", "");
  const response = await fetch(endpointPath, apiRequest);

  if (response.ok) {
    let result;
    switch (responseType) {
      case "JSON":
        result = await response.json();
        break;
      case "FILE":
        result = await response.blob();
        break;
    }
    return result;
  }

  //return errorResponse();
  throw new Error("An error occured while fetching request!");
}

export function errorResponse(): ApiResponse {
  const errorResponse: ApiResponse = {
    data: null,
    statusCode: 500,
    errors: ["An error occured while fetching request!"],
  };

  return errorResponse;
}
